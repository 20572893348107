<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <h3>
          陕西西咸新区服务贸易协会单位会员入会条件细则
        </h3>
        <h5>Application For Membership</h5>
        <div :style="{ 'max-height':maxHeight }" class="content">
          <h4>一、入会条件</h4>
          <p>第一条
            本会的会员为单位会员和个人会员。单位会员是本会的主体，个人会员不超过会员总数的10%。单位会员由其法定代表人或主要负责人作为代表参加本会的活动。单位会员更换代表，需向协会书面报告。单位发生合并、分立、终止等情况时，应报协会备案，其会员资格相应变更或终止。</p>
          <p>第二条 符合下列条件的，可以申请加入本会：</p>
          <p>（一）拥护本会的章程；</p>
          <p>
            （二）有加入本会的意愿；
          </p>
          <p>
            （三）在服务贸易领域具有一定的影响。
          </p>
          <p>
            （四）单位会员为本行业的经济组织，应持有工商营业执照（法律有特别规定的，需提交相关许可证）等相关证件，在本行业领域内具有一定的影响；
          </p>
          <p>
            （五）个人会员为在本行业或相关行业内有较大影响力的专家和管理人员。
          </p>
          <p>
            第三条 会员入会的程序是：
          </p>
          <p>
            （一）提交入会申请书；
          </p>
          <p>
            （二）经两名以上会员介绍；
          </p>
          <p>
            （三）单位会员须提供法人资格证明文件的复印件、单位基本情况介绍等资料；个人会员须提交身份证明、个人工作简历等资料；
          </p>
          <p>
            （四）经理事会讨论通过；
          </p>
          <p>
            （五）由理事会或理事会授权的机构发给会员证。
          </p>
          <p>
            第四条 会员享有下列权利：
          </p>
          <p>
            （一）选举权、被选举权和表决权；
          </p>
          <p>
            （二）对本会工作的知情权、建议权和监督权；
          </p>
          <p>
            （三）参加本协会的活动并获得本会服务的优先权；
          </p>
          <p>
            （四）入会自愿、退会自由。
          </p>
          <p>
            第五条 会员履行下列义务：
          </p>
          <p>
            （一）遵守协会的章程和各项规定，执行协会的决议；
          </p>
          <p>
            （二）维护协会合法权益；
          </p>
          <p>
            （三）完成协会交办的工作；
          </p>
          <p>
            （四）按规定交纳会费；
          </p>
          <p>
            （五）向协会反映情况，提供有关资料；
          </p>
          <p>
            （六）积极宣传并参与协会的各项活动；
          </p>
          <p>
            （七）为协会的发展献策出力；
          </p>
          <p>
            （八）联络方式更改时及时通知协会秘书处；
          </p>
          <p>
            第六条 会员如有违反法律法规和本章程的行为，经理事会表决通过，给予下列处分：
          </p>
          <p>
            （一）警告；
          </p>
          <p>
            （二）通报批评；
          </p>
          <p>
            （三）暂停行使会员权利；
          </p>
          <p>
            （四）除名。
          </p>
          <p>
            第七条 会员退会须书面通知协会秘书处，并交回会员证。
          </p>
          <p>
            第八条 会员有下列情形之一的，自动丧失会员资格或由协会给予除名：
          </p>
          <p>
            （一）一年不交纳会费；
          </p>
          <p>
            （二）两年不参加协会活动；
          </p>
          <p>
            （三）不再符合会员条件的；
          </p>
          <p>
            （四）丧失完全民事行为能力；
          </p>
          <p>
            （五）冒用本协会名义从事违法犯罪活动或给协会造成严重损害的。
          </p>
          <p>
            第九条 会员退会、自动丧失会员资格或者被除名后，其在协会相应的职务、权利、义务自行终止。
          </p>
          <h4>二、组织机构</h4>
          <h5>第一节 会员大会</h5>
          <p>第十条 会员大会是协会的最高权力机构，其职权是：</p>
          <p>
            （一）制订或修改章程；
          </p>
          <p>
            （二）选举或者罢免会长、副会长、理事、监事；
          </p>
          <p>
            （三）审议理事会、监事会的工作报告、财务预决算方案；
          </p>
          <p>
            （四）制定和修改会费标准；
          </p>
          <p>
            （五）制定和修改选举办法；
          </p>
          <P>
            （六）改变或者撤销理事会不适当的决定；
          </P>
          <p>
            （七）审议本会发展的重大事项；
          </p>
          <p>
            （八）决定终止事宜；
          </p>
          <p>
            （九）决定其他重大事宜。
          </p>
          <p>
            第十一条 会员大会每2年至少召开1次，每届5年。
            因特殊情况需提前或延期换届的，须由理事会表决通过，报经社团登记管理机关批准同意。但延期换届最长不超过1年。
            召开会员大会，须提前7日将会议的议程书面通知会员。
            会员大会不得采用通讯表决方式。
          </p>
          <p>
            第十二条 有1/5以上的会员或者理事会提议，须召开临时会员大会。
          </p>
          <p>
            第十三条 会员大会须有2／3以上的会员出席方能召开，其决议或决定须经全体会员的过半数表决通过，方能生效。
          </p>
          <p>
            第十四条 下列事项，须以无记名方式表决，并经全体会员的过半数表决通过：
          </p>
          <p>
            （一）制定和修改章程；
          </p>
          <p>（二）选举和罢免理事、会长、副会长。</p>
          <p>
            （三）制定或修改会费标准；
          </p>
          <h5>
            第二节 理事会
          </h5>
          <p>
            第十五条 理事会是会员大会的执行机构，负责行业协
            会日常工作，对会员大会负责。
            理事会由会长、副会长、秘书长、理事组成。
            理事会的人数不得超过会员总数的三分之一。
          </p>
          <p>
            第十六条 理事的产生程序：
          </p>
          <p>
            （一）第一届理事由发起人提名，会员大会选举产生；
          </p>
          <p>
            （二）理事会换届，由上一届理事会提名新一届理事候选人，会员大会选举产生；
          </p>
          <p>
            第十七条 单位理事的代表由该单位的主要负责人担任。
            单位调整理事代表，由其书面通知本会，报理事会备案。
          </p>
          <P>
            第十八条 理事会的职权是：
          </P>
          <p>
            （一）执行会员大会的决议；
          </p>
          <P>
            （二）聘任或者解聘秘书长；
          </P>
          <p>
            （三）决定名誉职务的设立和人选；
          </p>
          <p>
            （四）筹备召开会员大会；
          </p>
          <p>
            （五）向会员大会报告工作和财务状况；
          </p>
          <P>
            （六）决定办事机构、分支机构、代表机构和实体机构的设立、变更和注销；
          </P>
          <P>
            （七）决定副秘书长、各办事机构主要负责人的人选；
          </P>
          <p>
            （八）领导协会各机构开展工作；
          </p>
          <p>
            （九）审议协会年度工作报告和工作计划；
          </p>
          <p>
            （十）审议协会年度财务预算、决算；
          </p>
          <p>
            （十一）制定内部管理制度；
          </p>
          <p>
            第十九条 理事会每届5年。理事会与会员大会届期相同，与会员会届期同时换届。因特殊情况需提前或者延期换届的，由理事会表决通过，报登记管理机关同意。延期换届最长不超过1年。
          </p>
          <p>
            第二十条 理事会会议应当有三分之二以上的理事会人员出席方可举行，其做出的决议或者决定应当经全体理事会人员的过半数表决通过。
          </p>
          <p>
            第二十一条 理事会每年至少召开一次会议。召开理事
            会，须提前7日将会议的议程书面通知理事。理事3次不出席理事会会议，自动丧失理事资格
          </p>
          <P>
            第二十二条 三分之一的理事提议，须召开临时理事会
            会议。
          </P>
          <h5>第三节 负责人</h5>
          <p>第二十三条 协会的负责人包括：会长、副会长和秘书长</p>
          <p>
            协会负责人须具备下列条件：
          </p>
          <P>
            （一）在协会业务领域内有较大影响，有从事本行业3年以上经历，熟悉行业情况，在行业内有良好声誉；
          </P>
          <p>（二）最高任职年龄不超过70周岁；</p>
          <p>
            （三）身体健康，能坚持正常工作；
          </p>
          <p>（四）未受过剥夺政治权利的刑事处罚的；</p>
          <p>（五）具有完全民事行为能力；</p>
          <p>第二十四条
            协会会长、副会长、秘书长如超过最高任职年龄的，须经理事会表决通过，报社团登记管理机关批准同意后，方可任职。会长、秘书长不得由同一会员单位的人员担任，不得是近亲属，或者具有其他利害关系。会长不得兼任秘书长。</p>
          <p>
            第二十五条 会长为本会法定代表人。本会会长不兼任其他社会团体的法定代表人。
          </p>
          <p>第二十六条 本会会长行使下列职权：</p>
          <p>
            （一）代表协会签署有关重要文件；
          </p>
          <p>（二）召集和主持理事会；</p>
          <P>（三）检查会员大会、理事会决议的落实情况；</P>
          <p>
            第二十七条 协会秘书长协助会长工作，秘书长为专职，行使下列职权：
          </p>
          <p>
            （一）主持办事机构开展日常工作，组织实施年度工作计划；
          </p>
          <p>
            （二）协调各分支机构、代表机构、实体机构开展工作；
          </p>
          <p>
            （三）提名副秘书长以及各办事机构、分支机构、代表机构和实体机构主要负责人，交理事会决定；
          </p>
          <p>
            （四）决定办事机构、代表机构、实体机构专职工作人员的聘用；
          </p>
          <p>
            （五）处理其他日常事务。
          </p>
          <p>
            第二十八条 会员大会、理事会、负责人会议应当制作会议记录。形成决议的，应当制作书面决议，并由负责人审阅、签名。会议记录、会议决议应当以适当方式向会员通报，并保存年十年。
          </p>
          <p>临时会员大会、临时理事会会议，均不得研究提议议题之外的事项。</p>
        </div>
        <button v-if="maxHeight!=''" @click="open">查看更多</button>
      </div>
      <div slot="rightDiv">
        <div class="information">
          <h4>发送邮箱</h4>
          <p>service@xxatis.com</p>
          <p>入会资料请发送至以上邮箱，我们将在15个工作日内受理并回复。（入会申请表请点击x下方附件下载，填写完成后请以扫描件或者照片的形式发送至邮箱。）</p>
        </div>
        <div class="information">
          <h4>入会附件下载</h4>
          <p><a style="color: black;text-decoration: none" :href="url">附件1 《陕西西咸新区服务贸易协会单位会员入会申请表》</a></p>
          <p><a style="color: black;text-decoration: none" :href="url2">附件1 《陕西西咸新区服务贸易协会会员理事单位申请表》</a></p>
        </div>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";

export default {
  name: "applicationMembership",
  data() {
    return {
      url:this.$constContent.imgUrl+"static/file/陕西西咸新区服务贸易协会会员理事单位申请表.docx",
      url2:this.$constContent.imgUrl+'static/file/陕西西咸新区服务贸易协会单位会员入会申请表.docx',
      maxHeight:'1000px'
    }
  },
  components: {
    leftAndRight
  },
  methods:{
    open(){
      this.maxHeight=''
    }
  }
}
</script>

<style scoped>

.page >>> .imgAndWord {
  background-image: url("../../assets/image/ruihuishenqing.png");
}

p {
  line-height: 24px;
  font-size: 14px;
  text-indent: 2em;
}
.content{
  overflow: hidden;
  margin-bottom: 20px;
}

.information {
  background: #f8f8f8;
  padding: 30px 18px;
  margin-bottom: 10px;
  border: 1px solid #dfdfdf;
}
.information p,h4{
  text-indent: 0;
}

.leftDiv button {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background: #eeeeee;
  border: 1px solid #dfdfdf;
}

.rightDiv img {
  margin-top: 10px;
  width: 100%;
}
</style>
