<template>
  <div class="div">
    <div class="leftDiv">
      <slot name="leftDiv"></slot>
    </div>
    <div class="rightDiv">
      <slot name="rightDiv"></slot>
    </div>
  </div>
</template>

<script>
export default {
name: "leftAndRight"
}
</script>

<style scoped>
.div {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.leftDiv {
  width: 65%;
  float: left;
  display: block;
}

.rightDiv {
  width: calc(35% - 20px);
  padding-left: 20px;
  float: left;
  display: block;
}
</style>